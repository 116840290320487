import ResponsiveBox from 'components/layout/ResponsiveBox';
import styled from 'styled-components';
import theme from 'utils/theme';
import Link from 'components/Link';

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.white,
});

export const Wrapper = styled.div({
  marginBottom: '16px',
});

export const StyledSiteLink = styled(Link)`
  display: inline-block;
  width: 100%;
`;

export const ProductDetails = styled.div({
  marginTop: '16px',
});

interface SkeletonTextProps {
  width: number;
}

export const SkeletonResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.darkBg,
});

export const SkeletonText = styled.span<SkeletonTextProps>((props) => ({
  display: 'inline-block',
  backgroundColor: theme.colors.darkBg,
  color: 'transparent',
  width: '100%',
  maxWidth: props.width,
  userSelect: 'none',
}));

export const ProductName = styled.div({
  color: theme.colors.black,
  fontFamily: theme.fonts.body,
  fontWeight: theme.weights.bold,
  fontSize: '16px',
  marginTop: '16px',
});

export const ProductPrice = styled.div({
  color: theme.colors.darkGreen,
  fontWeight: theme.weights.bold,
  marginTop: '8px',
});

export const ColorWrapper = styled.div({
  marginTop: 8,
});

export const VariantDetails = styled.ul({
  color: theme.colors.grey,
  fontWeight: theme.weights.bold,
  listStyleType: 'none',
  marginTop: '8px',
  marginBottom: 0,
  padding: 0,
  li: {
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});
