import React from 'react';
import Image from 'components/Image';
import ColorSelector from 'components/ColorSelector';
import { mobile, tablet } from 'utils/media';
import formatPrice from 'utils/formatPrice';
import { getProductUrl } from 'utils/commercetools/product';
import {
  ColorWrapper,
  Wrapper,
  ProductDetails,
  ProductName,
  ProductPrice,
  VariantDetails,
  StyledResponsiveBox,
  SkeletonText,
  SkeletonResponsiveBox,
  StyledSiteLink,
} from './styles';
import { ProductItemProps } from './types';

export const ProductSkeleton = () => (
  <Wrapper>
    <SkeletonResponsiveBox aspectRatio={1} rounded />
    <ProductDetails>
      <ProductName>
        <SkeletonText width={170}>{'\u00A0'}</SkeletonText>
      </ProductName>
      <ProductPrice>
        <SkeletonText width={55}>{'\u00A0'}</SkeletonText>
      </ProductPrice>
    </ProductDetails>
  </Wrapper>
);

const ProductItem = ({ product, 'data-testid': testId }: ProductItemProps) => {
  const { name, photo, shortDescription, colors, attributeRanges } = product;

  return (
    <Wrapper data-testid={testId}>
      <StyledSiteLink href={getProductUrl(product)} title={shortDescription}>
        <StyledResponsiveBox aspectRatio={1} rounded>
          {photo ? (
            <Image
              loader="cloudinary"
              src={photo}
              aspectRatio={1}
              sizes={`${mobile} 150px, 290px`}
              alt={name}
            />
          ) : null}
        </StyledResponsiveBox>
        <ProductDetails>
          <ProductName>{name}</ProductName>
        </ProductDetails>
        {attributeRanges?.length ? (
          <VariantDetails>
            {attributeRanges.map((attr, index) => (
              <li key={index}>{attr}</li>
            ))}
          </VariantDetails>
        ) : null}
      </StyledSiteLink>
      <div>
        {colors && colors.length > 1 ? (
          <ColorWrapper>
            <ColorSelector
              size="small"
              colors={colors}
              limit={{ [mobile]: 3, [tablet]: 5 }}
            />
          </ColorWrapper>
        ) : null}
        {product.fromPrice || product.price ? (
          <ProductPrice>
            {product.fromPrice
              ? `From ${formatPrice(product.fromPrice)}`
              : product.price
              ? formatPrice(product.price)
              : null}
          </ProductPrice>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default ProductItem;
