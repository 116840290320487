import styled, { CSSProp } from 'styled-components';
import { mobile } from 'utils/media';
import { Columns } from './types';

interface GridProps {
  columns: Columns;
}

export const Grid = styled.div<GridProps>((props) => {
  const baseStyles: CSSProp = {
    display: 'grid',
    gridAutoFlow: 'row dense',
    columnGap: 40,
    rowGap: 50,
  };

  const breakpoints = Object.entries(props.columns).map(
    ([breakpoint, width]) => ({
      [breakpoint]: {
        gridTemplateColumns: `repeat(${width}, 1fr)`,
      },
    }),
  );

  const stylesWithBreakpoints = Object.assign(baseStyles, ...breakpoints);

  stylesWithBreakpoints[mobile] = {
    ...stylesWithBreakpoints[mobile],
    columnGap: 24,
    rowGap: 40,
  };

  return stylesWithBreakpoints;
});
