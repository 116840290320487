import styled from 'styled-components';
import theme from 'utils/theme';

interface ColorDotProps {
  color: string;
  size: string;
}

export const ColorDot = styled.div<ColorDotProps>(({ color, size }) => ({
  display: 'inline-block',
  width: size === 'small' ? 16 : 24,
  height: size === 'small' ? 16 : 24,
  borderRadius: '100%',
  backgroundColor: color,
}));

interface ColorDotWrapperProps {
  selected: boolean;
  color: string;
  href?: string;
}

export const ColorDotWrapper = styled.div<ColorDotWrapperProps>(
  ({ selected, href, color }) => ({
    display: 'inline-block',
    borderRadius: '100%',
    padding: 2,
    borderWidth: 2,
    fontSize: 0,
    lineHeight: 0,
    borderStyle: 'solid',
    marginTop: 10,
    borderColor: selected
      ? theme.colors.purple
      : href
      ? theme.colors.iconUnderlay
      : color,
  }),
);
